import imageUrlBuilder from '@sanity/image-url'
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import { createClient } from '@sanity/client';

let PROJECT_ID = "c4oisv0v";
let DATASET = "production";

document.addEventListener('DOMContentLoaded', async () => {
  const client = createClient({
    projectId: PROJECT_ID,
    dataset: DATASET,
    useCdn: true,
    apiVersion: '2023-05-03',
  });

  const currentPage = window.location.pathname;

  const builder = imageUrlBuilder(client);

  if (currentPage === '/') {
    const promoItems = await client.fetch('*[_type == "promo"]');

    promoItems.forEach((promoItem) => {
      console.log(promoItem);
      document.getElementById('line1').textContent = promoItem.line1;
      document.getElementById('line2').textContent = promoItem.line2;
      document.getElementById('cash').textContent = promoItem.cashback;
      document.getElementById('promo_line1').textContent = promoItem.promo_line1;
      document.getElementById('promo_line2').textContent = promoItem.promo_line2;
      document.getElementById('promo_line3').textContent = promoItem.promo_line3;

      const promoImage = promoItem.promo;

      const imageUrl = builder.image(promoImage).quality(80);

      document.getElementById('promo').src = imageUrl;
    });
  } else if (currentPage === '/catalog') {
    const catalogItems = await client.fetch('*[_type == "catalog"]');

    var splide = new Splide("#main-slider", {
      width: 600,
      height: 300,
      pagination: false,
      cover: true
    });

    var mainSlider = document.querySelector('.splide__list');
    var thumbnails = document.querySelector('#thumbnails');

    const maxThumbnails = 5;
    let thumbnailCount = 0;

    catalogItems[0].image.forEach((catalogImage, index) => {
      const imageUrl = builder.image(catalogImage).quality(60);

      // Create and append slides to main slider
      let slide = document.createElement('li');
      slide.classList.add('splide__slide');
      let slideImg = document.createElement('img');
      slideImg.src = imageUrl;
      slide.appendChild(slideImg);
      mainSlider.appendChild(slide);

      // Create and append thumbnails
      let thumbnail = document.createElement('li');
      thumbnail.classList.add('thumbnail');
      let thumbnailImg = document.createElement('img');
      thumbnailImg.src = imageUrl;
      thumbnail.appendChild(thumbnailImg);
      thumbnails.appendChild(thumbnail);

      // Event listener for thumbnail
      thumbnail.addEventListener("click", function () {
        splide.go(index);
      });

      if (thumbnailCount >= maxThumbnails) {
        thumbnail.style.display = 'none';
      }

      thumbnailCount++;
    });

    let current;

    // Function to toggle the visibility of the first and last thumbnails
    function toggleThumbnailVisibility(thumbnails, showFirst, showLast) {
      const firstThumbnail = thumbnails.firstElementChild;
      const lastThumbnail = thumbnails.lastElementChild;

      firstThumbnail.style.display = showFirst ? 'block' : 'none';
      lastThumbnail.style.display = showLast ? 'block' : 'none';
    }

    splide.on("mounted move", function () {
      var thumbnail = thumbnails.children[splide.index];

      if (thumbnail) {
        if (current) {
          current.classList.remove("is-active");
        }

        thumbnail.classList.add("is-active");
        current = thumbnail;
      }

      // Toggle visibility of first and last thumbnails dynamically
      toggleThumbnailVisibility(thumbnails, splide.index >= maxThumbnails, thumbnailCount < catalogItems[0].image.length);

      if (thumbnailCount < catalogItems[0].image.length) {
        // Load additional thumbnail when user navigates to next slide
        if (splide.index === thumbnailCount - maxThumbnails) {
          const catalogImage = catalogItems[0].image[thumbnailCount];
          const imageUrl = builder.image(catalogImage).quality(60);

          // Create and append thumbnail
          let thumbnail = document.createElement('li');
          thumbnail.classList.add('thumbnail');
          let thumbnailImg = document.createElement('img');
          thumbnailImg.src = imageUrl;
          thumbnail.appendChild(thumbnailImg);
          thumbnails.appendChild(thumbnail);

          // Event listener for thumbnail
          thumbnail.addEventListener("click", function () {
            splide.go(thumbnailCount);
          });

          thumbnailCount++;
        }
      }
    });

    splide.mount();
  } 
});
